export const languages = {
  LV: "LV",
  RU: "RU",
};

export const locales = [
  {
    language: languages.LV,
    messages: {
      aboutUs: "PAR MUMS",
      services: "PAKALPOJUMI",
      cosmetics: "KOSMĒTIKA",
      promotions: "AKCIJAS",
      contacts: "KONTAKTI",
    },
  },
  {
    language: languages.RU,
    messages: {
      aboutUs: "О НАС",
      services: "УСЛУГИ",
      cosmetics: "КОСМЕТИКА",
      promotions: "АКЦИИ",
      contacts: "КОНТАКТЫ",
      aboutPage: {
        header: `Красоту невозможно искусственно создать, красота – это
                удивительное внутреннее состояние гармонии, легкости,
                одухотворенности и комфорта. Это окрыленное чувство влюбленности —
                в себя и окружающий современные технологии в работе.`,
        paragraph1: `Милые дамы и уважаемые господа, приветливый коллектив салона
                «Lanta» предлагает Вам вспомнить о себе, окунуться в атмосферу
                тепла и доброжелательности. Мы рады помочь, оказывая наши услуги,
                представительницам прекрасного пола и мужчинам всегда оставаться
                неотразимыми и желанными. Наш салон успешно работает в Даугавпилсе
                с 1998 года, постоянно расширяя спектр своих услуг, применяя
                современные технологии в работе.`,
        paragraph2: `Мы рады каждому клиенту! Наши опытные мастера постоянно повышают
                свою квалификацию, проходят различные курсы обучения, благодаря
                чему оказывают своим клиентам услуги только высшего качества.
                Помогает им в этом также богатая фантазия и современное оборудование.`,
        paragraph3: `Наша философия - давать клиенту не только сервис, но и дружеское
                отношение.`,
        paragraph4: `Наша цель – сделать вас счастливыми.`,
        paragraph5: `Наш вектор - следовать мировым трендам и тенденциям.`,
      },
      preview: {
        spotText: "Будь красивой",
        spotTextMall: "Салоны красоты в даугавпилсе",
      },
      servicesPage: {
        pageName: "Наши услуги",
        hairdresserServices: "УСЛУГИ ПАРИКМАХЕРА",
        womenHaircuts: "Женские стрижки",
        manHaircuts: "Мужские стрижки",
        childsHaircuts: "Детские стрижки",
        coloring: "Различные виды окрасок (мелирование, омбре)",
        laying: "Укладка",
        manicurePedicure: "МАНИКЮР/ПЕДИКЮР",
        manicure: "Классический маникюр",
        pedicure: "Классический педикюр",
        nailExtension: "Наращивание ногтей (гел/ акрил)",
        gelish: "Гелиш",
        correction: "Коррекция",
        therapeuticManicure: "Лечебный маникюр",
        therapeuticPedicure: "Лечебный педикюр",
        bodyAndFaceCare: "УХОД ЗА ТЕЛОМ И ЛИЦОМ",
        piercing: "Пирсинг",
        waxing: "Ваксинг",
        eyebrowCorrection: "Коррекция бровей",
        eyelashes: "Покраска ресниц",
        eyelashExtension: "Наращивание ресниц",
        eyelashPerm: "Завивка ресниц",
        makeup: "Макияж",
        cleansesAndFaceMasks: "Чистки и маски для лица",
        solarium: "СОЛЯРИЙ",
        horizontal: "Горизонтальный солярий (Ergoline Turbo 450)",
        vertical: "Вертикальный солярий (Luxura)",
        sunCream: "Крема для загара",
      },
      contactsPage: {
        contacts: "Наши контакты",
        mon_fri: "Пн.-Пт. {time}",
        sat: "Сб. {time}",
        sun: "Вс. {time}",
        mon: "Пн. {time}",
        tue_fri: "Вт.-Пт. {time}",
        address: "Адрес",
        tell: "Телефон",
        workTime: "Время работы",
        email: "Э-почта",
      },
      cosmeticsPage: {
        pageName: "Наша косметика",
      },
      discountPage: {
        happyDays: "СЧАСТЛИВЫЙ ДЕНЬ",
        sunday: "Воскресенье",
        discountCosmetics: "{percent} на косметику",
        discountSolarium: "{percent} на солярий",
        happyHours: "СЧАСТЛИВЫЕ ЧАСЫ",
        discountTime: "С 8.00 ДО 10.00",
        subscription: "АБОНЕМЕНТ",
      },
    },
  },
];

export const discountPercents = {
  twenty: "-20%",
  fifteen: "-15%",
};
